button.icon {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

/* Code */
.code {
  position: relative;
}

.code .copy-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 5;
}

#menu-open:checked~#sidebar {
  --tw-translate-x: 0;
}

#menu-open:checked~* #menu-open-icon {
  display: none;
}

#menu-open:checked~* #menu-close-icon {
  display: block;
}

.sidebar {
  transition: width 0.3s ease-in-out;
}

.sidebar:hover {
  width: 200px;
}